<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit Post</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <div>
                          <div class="form-group col">
                            <label for="title">Title:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="title"
                              placeholder="Enter Title"
                              v-model.trim="details.title"
                              @input="setTouch('title')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.title.required && $v.details.title.$error"
                            >
                              Title is required
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="subTitle">Sub-Title:</label>
                            <input
                              type="text"
                              class="form-control"
                              id="subtitle"
                              placeholder="Enter Sub Title"
                              v-model.trim="details.subTitle"
                              @input="setTouch('subTitle')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.subTitle.required && $v.details.subTitle.$error"
                            >
                              Sub Title is required
                            </div>
                          </div>
                        </div>
                        <!-- <div>
                          <div class="form-group col">
                            <label for="image"> Select Image:</label> <br />

                            <v-file-input
                              prepend-icon=""
                              type="file"
                              class="form-control"
                              id="designer-image"
                              v-model.trim="details.image"
                              @change="handleImage"
                              accept="image/jpeg, image/png"
                            ></v-file-input>
                            <div
                              class="validation"
                              v-if="!$v.details.image.required && $v.details.image.$error"
                            >
                              Image is required
                            </div>
                          </div>
                          <div class="preview-image border p-2 mt-3 ml-3" v-if="url">
                            <v-img :src="url" height="230" contain></v-img>
                          </div>
                        </div> -->
                        <div class="d-flex">
                          <div class="form-group col-6">
                            <div>
                              <label for="image">Mobile Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (120 W &#10005; 148 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.imageMobile"
                                placeholder=" Select Image"
                                accept="image/jpeg, image/png"
                                @change="handleMobileImage"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="
                                  !$v.details.imageMobile.required && $v.details.imageMobile.$error
                                "
                              >
                                Mobile Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                              <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                            </div>
                          </div>

                          <div class="form-group col-6">
                            <div>
                              <label for="image"> Desktop Image:</label>
                              <div class="font-12 black--text font-weight-bold">
                                (240 W &#10005; 327 H)
                              </div>
                              <v-file-input
                                prepend-icon=""
                                type="file"
                                class="form-control"
                                id="banner-image"
                                v-model.trim="details.image"
                                placeholder=" Select Image"
                                @change="handleDesktopImage"
                                accept="image/jpeg, image/png"
                              ></v-file-input>
                              <div
                                class="validation"
                                v-if="!$v.details.image.required && $v.details.image.$error"
                              >
                                Desktop Image is required
                              </div>
                            </div>
                            <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                              <v-img :src="desktopUrl" height="230" contain></v-img>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="form-group col">
                            <label for="email">Link:</label>

                            <input
                              type="text"
                              id="Link"
                              class="form-control"
                              name="Link"
                              placeholder="Link"
                              v-model.trim="details.link"
                              @input="setTouch('link')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.link.required && $v.details.link.$error"
                            >
                              Link is required
                            </div>
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  components: {},
  data() {
    return {
      details: {
        title: '',
        subTitle: '',
        image: '',
        imageMobile: '',
        link: '',
      },
      submitStatus: false,
      id: '',
      mobileUrl: null,
      desktopUrl: null,
    };
  },
  validations: {
    details: {
      title: {
        required,
      },
      subTitle: {
        required,
      },
      image: {
        required,
      },
      imageMobile: {
        required,
      },
      link: {
        required,
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOnePost(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'title') {
        this.$v.details.title.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      } else if (fields === 'imageMobile') {
        this.$v.details.image.$touch();
      } else if (fields === 'link') {
        this.$v.details.link.$touch();
      } else if (fields === 'subTitle') {
        this.$v.details.subTitle.$touch();
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        const result = await homeService.updatePost(id, this.details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewPosts');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
        console.log(result);
      }
    },
    async getOnePost(id) {
      console.log(id, 'id');
      const result = await homeService.getOnePost(id);
      this.details.title = await result.data.title;
      this.details.subTitle = await result.data.subTitle;
      this.details.image = await result.data.image;
      this.details.imageMobile = await result.data.imageMobile;
      this.details.link = await result.data.link;
      this.desktopUrl = await result.data.image;
      this.mobileUrl = await result.data.imageMobile;
      console.log(result.data.image, 'result');
    },

    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewPosts');
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl =  URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.image = result.data.meta.location;
          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 40%;
  height: 250px;
}
</style>
